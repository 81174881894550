*{
    margin: 0px;
    padding: 0px;
}

body{
    background: url(../image/bk2.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #f9f8fe;
}

p, h1, h2, h3, h4, h5, h6, footer a{
    font-family: 'Baloo Tammudu 2', cursive !important;
}
.softMaterial--brand, .root, body{
    position: relative !important;
    display: block !important;
    overflow-x: hidden;
}

.container-re{
    width: 100%;
	max-width: 90%;
	margin: 0 auto;
}

.header{
    /*background: url(../../assets/image/bk2.jpg);
    background-size: cover;*/
    position: relative;
    display: block;
    width: 100%;
    /*min-height: 100vh;*/
}
.header-h1{
    color:#272443;
    font-size: 4rem;
    line-height: 4.5rem;
    position: relative;
    display: block;
    width: 540px;
    margin: 160px auto;
    margin-bottom: 0px;
}
.star-icon{
    font-size: 3rem !important;
    color: #ffde2f;
}
.octogon-icon{
    font-size: 3rem !important;
    color: #91df84;
}

@media (max-width: 500px){
    body{
        background: url(../../assets/image/bk2.jpg);
        background-size: auto;
        background-position: center 50px;
    }
}

nav .navbar-logo{
    font-family: 'Baloo Tammudu 2', cursive !important;
}

.textBolder{
    font-weight: 600 !important;
}
/*
.hexagon{
    width: 100px;
    height: 100px;
    position: absolute;
    top: 300px;
    left: 500px;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background-color: rgb(233 233 233 / 10%);
    background: url(../image/test.png);
    background-size: contain;
    background-repeat: no-repeat;
}
*/
nav .navbar-logo{
    margin: 20px;
}
nav.scrollable{
    transition: .3s ease-in-out;
    transform: translate(0, -100px);
    position: fixed;
    top: 0px;
}
nav.fixed{
    transform: translate(0, 0);
    /*background: #fff;*/
    z-index: 10;
    width: calc(100% - 20px);
    border-radius: 10px;
}
nav.scrollable .navbar-logo{
    margin: 10px;
}
nav.scrollable.open_moby_menu{
    z-index: 999;
}
nav.scrollable.open_moby_menu ul{
    width: 100%;
}
nav.scrollable .nvb-wrapper ul li{
    margin: 0px;
}
.pst-esp{
    background: white;
    border-radius: 6px;
    margin: 10px 0;
}
nav.open_menu .container-re{
    position: relative;
    background-color: #fff;
    max-width: 100%;
    z-index: 999;
}
nav.open_menu .menu-esp{
    left: 37.5px;
}
nav.open_menu ul{
    background: #fff;
    display: block;
    padding: 0px 40px;
    box-shadow: 1px 11px 11px -12px #0000002e;
}
nav.open_menu ul li, nav.open_menu ul li a{
    width: 100%;
    display: block;
    text-align: left;
}
nav.open_menu ul li:first-child a{
    margin-top: 10px;
}
nav.open_menu ul li:last-child a{
    margin-bottom: 10px;
}
nav.open_menu ul li a{
    /*padding: 15px 40px;*/
    position: relative;
    margin: 0;
    padding: 0 15px;
    line-height: 50px;
}

.hexagon_bx{

}
.hexagon{
    width: 128px;
    height: 110px;
    -webkit-clip-path: url(#hexagono);
    clip-path: url(#hexagono);
    -webkit-clip-path: polygon(25% 0, 75% 0, 100% 50%, 75% 100%, 25% 100%, 0 50%);
    clip-path: polygon(25% 0, 75% 0, 100% 50%, 75% 100%, 25% 100%, 0 50%);
    margin-right: calc(var(--size) / 2);
    color: #fff;
    overflow: hidden;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(2px);
    background-color: rgb(233 233 233 / 10%);
    position: absolute;
    left: 40%;
    top: 146px;
    transform: rotate(-75deg);
    background: url(../image/hex.png);
    background-size: contain;
    background-repeat: no-repeat;
    z-index: 2;
}
.hexagon::before {
	content: '';
	float: left;
	width: 25%;
	height: 100%;
	clip-path: polygon(0% 0%, 100% 0%, 0% 50%, 100% 100%, 0% 100%);
	shape-outside: polygon(0% 0%, 100% 0%, 0% 50%, 100% 100%, 0% 100%);
}

.triangle {
	width: 0;
	height: 0;
    border-top: 60px solid transparent;
    border-right: 100px solid #555;
    border-bottom: 60px solid transparent;
    position: absolute;
    top: 460px;
    left: 980px;
}
section{
    background-color: #fff;
}

.section_1{
    padding-bottom: 65px;
    background: transparent;
}

.img_bk_esp{
    width: 1024px;
    height: 440px;
    background: url(../image/softmaterial.png);
    background-size: cover;
    margin: 0 auto;
    opacity: .9;
    transform: perspective(300px) rotateX(10deg);
    border-radius: 30px;
    /*border: 5px solid #fff;
    box-shadow: 0px 0px 0px 1px #d6d4d4;*/
    margin-bottom: 50px;
}
.t1_esp{
    color: #616161;
    font-weight: 500;
}
.link_scroll_esp{
    margin: 0 auto;
    display: block;
    /* margin-top: 65px; */
    border-radius: 50%;
    background: #fff;
    width: 130px;
    height: 130px;
    text-align: center;
    position: absolute;
    left: calc(50% - 65px);
}
.link_scroll_esp span{
    margin-top: 25px;
    font-size: 5rem;
    color: #000;
    transform: rotate(-90deg);
}
.sect3{
    background: url(../image/bk-sect2.5.jpg);
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    opacity: 1;
}
.sect4{
    background: url(../image/bk-sect4.jpg);
    min-height: 100vh;
    background-size: 100%;
    opacity: 1;
}

.sect5{
    background-color: #fafaf8;
    position: relative;
}

.sect3 .port_as2{
    position: relative;
    padding-right: 40px;
    margin-top: 100px;
}
.sect3 .port_as3 div, .sect3 .port_as3 {
    margin-top: 0;
}
.sect3 .port_as3 div p{
    /*display: flex;*/
    text-align: right;
}
.sect3 .port_as3 div p i{
    margin-left: 10px;
    position: relative;
    top: 7px;
}
.sect3 h2, .sect4 h2{
    font-weight: 500;
    text-transform: uppercase;
}
.sect3 p{
    margin-bottom: 20px;
    font-weight: 500;
    color: #727272;
}
.sect3 span{
    font-weight: 500;
    color: #bbbbbb;
    letter-spacing: 2px;
    font-style: italic;
}
.sect3 .line-res_espo{
    width: 35px;
    height: 2.5px;
    background: #bbbbbb;
    display: block;
    position: absolute;
    right: 0px;
    top: 12px;
    margin-right: -10px;
}
.sect3 .me_info_espo:first-child{
    margin-top: 20px;
}
.sect3 .me_info_espo{
    display: flex;
}
.sect3 .me_info_espo p{
    width: 100%;
    text-align: right;
    color: #727272;
    display: inherit;
}
.sect3 .me_info_espo p i{
    margin-left: 10px;;
}

.sect4 .ps_sct4_esp{
    margin: 50px 0 0 0;
}
.crd-cont-esp p{
    color: #868686;
    font-size: .8rem;
    font-weight: 500;
    line-height: 1.4;
    margin-top: 10px;
}

.crd-cont-esp span{
    display: block;
    margin-bottom: 20px;
    color: #2ba7ff;
    font-weight: 500;
}
.label-bar-esp{
    width: 140px;
    display: block;
    text-align: right;
    font-size: .8rem;
}
.progressBar{
    border-radius: 3px;
    width: 100%;
    height: 3px;
    background: #f1f1f1;
    margin: 12px;
    position: relative;
}
.progressBar .progress{
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100%;
}
.progress-percentage {
    font-size: .8rem;
    color: #8f8f8f;
    font-weight: 500;
}

section h2{
    font-size: 1.5rem;
    font-weight: 700 !important;
    text-transform: uppercase;
}
section .sub-title-esp{
    display: flex;
    margin-left: -52px;
}
section .sub-title-esp span{
    color: #989898;
    font-weight: 500;
    letter-spacing: 3px;
    font-size: 1rem;
    font-style: italic;
}
section .sub-title-esp .line-res_esp{
    width: 38px;
    height: 2.5px;
    display: block;
    background: #c6c6c6;
    margin-top: 10px;
    margin-right: 15px;
}

section .portfolio-esp{
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    background: url(../../assets/image/test.jpg);
    position: relative;
    height: 339.98px;
    background-position: center;
    background-size: cover;
}

section .portfolio-esp:hover .infoCard{
    opacity: 1;
}
section .portfolio-esp .infoCard{
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
    border-radius: 10px;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);
    background-color: rgb(233 233 233 / 50%);
    opacity: 0;
    transition: .2s ease-in-out;
line-height: 17;
    text-align: center;
    padding-top: 0px;
    color: #26669c;
    font-size: 1.2rem;
    font-weight: 800;
}
ul li{
    margin: 20px;
}
ul li a{
    font-weight: 500;
    color: #989898;
    font-size: .9rem;
    text-align: center;
}
ul li a.active{
    color: #03a9f4;
}
ul li a.active:after{
    content: "";
    display: block;
    width: 5px;
    height: 5px;
    background: #03a9f4;
    border-radius: 50%;
    margin: 3px auto;
}

.text-p{
    font-size: .9rem;
    color: #717171;
    font-weight: 500;
}
section{
    padding: 60px 0 !important; 
}

.title-contact-esp{
    font-size: 2.2rem;
    text-transform: none;
    color: #394153;
}

section.contact p{
    font-size: 1.2rem;
    color: #60697d;
    font-weight: 500;
}
section.contact{
    position: relative;
    padding-bottom: 200px !important;
}
section.contact::before{
    content: "";
    background-color:#f7f9ff;
    position: absolute;
    top: 0px;
    left: 0;
    width: 600px;
    height: 600px;
    border-radius: 50%;
    transform: translate(-50%, -50%);
}
section.contact::after{
    content: "";
    background-image:url(../image/contact.png);
    background-repeat: no-repeat;
    position: absolute;
    top: 45%;
    left: 0;
    transform: translate(200px, 0%);
    width: 310px;
    height: 360px;
}

@media (max-height: 1810px){
    section.contact::before{
        content: "";
        width: 400px;
        height: 400px;
        transform: translate(-50%, -50%);
        z-index: 0;
    }
    section.contact::after{
        content: "";
        transform: translate(100px, 0%);
        z-index: 0;
    }
}

section.contact .form-box{
    background-color: #f0f7fb;
    border-radius: 10px;
    padding: 10px 15px;
}
.form-box .input-material:focus:not(:disabled) + label, 
.form-box .input-material.full + label, .form-box .input-material:not([value=""]):not(:focus):not(:required):invalid + label, .placeholder-label.date{
    top: 6px !important;
}
section.contact .form-box .placeholder-label{
    top: 27px;
    font-size: 1.2rem;
}
section.contact .form-box input, section.contact .form-box textarea{
    background-image: none !important;
    border-radius: 10px;
}
section.contact .form-box.error_input{
    background-color: rgb(255 91 91);
}

section.contact .form-box.error_input .placeholder-label{
    color: #fff;
}

section.contact .form-box .characters_count{
    background: #fff;
    padding: 5px 10px;
    margin-top: -3px;
    border-radius: 3px;
    font-weight: 600;
}

.base-line-esp{
    background-image: url(../image/base-line.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 100px;
    height: 250px;
    width: 101%;
    background-color: #fff;
    margin-top: 0px !important;
    margin-left: -0px;
}
.base-line-esp .assistent-esp{
    transform: translate(-80px, -450px) scaleX(-1) scale(.9);
    -webkit-transform: translate(-80px, -450px) scaleX(-1) scale(.9); 
    -moz-transform: translate(-80px, -450px) scaleX(-1) scale(.9);
    -o-transform: translate(-80px, -450px) scaleX(-1) scale(.9);
     transform: translate(-80px, -450px) scaleX(-1) scale(.9); 
    filter: FlipH;
    -ms-filter: "FlipH";
}

footer{
    background-color: #1c3b8f;
    margin-top: -1px !important;
}

footer:before{
    content: "";
}

.arrow-right {
    width: 0px;
    height: 0px;
    border-top: 35px solid transparent;
    border-bottom: 35px solid transparent;
    border-left: 50px solid #fde090;
    position: absolute;
}

.triangle-line{
    width:40%;
    padding-bottom: 28.30%; /* = width / sqrt(2) */
    position: absolute;
    border-bottom: 3px solid #8cd4ee;
    border-right: 3px solid transparent;
    border-left: 3px solid transparent;
    overflow: hidden;
}
.triangle-line:before{
    content:'';
    position:absolute;
    bottom:0; left:0;
    width:100%; height:100%;
    border-top:3px solid #8cd4ee;
    border-left:3px solid #8cd4ee;
    
    -moz-box-sizing:border-box;
    box-sizing:border-box;
      
    -webkit-transform-origin:0 100%;
    -ms-transform-origin:0 100%;
    transform-origin:0 100%;
      
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.triangle-line2{
    width:40%;
    padding-bottom: 28.30%; /* = width / sqrt(2) */
    position: absolute;
    border-bottom: 3px solid #fce190;
    border-right: 3px solid transparent;
    border-left: 3px solid transparent;
    overflow: hidden;
}
.triangle-line2:before{
    content:'';
    position:absolute;
    bottom:0; left:0;
    width:100%; height:100%;
    border-top:3px solid #fce190;
    border-left:3px solid #fce190;
    
    -moz-box-sizing:border-box;
    box-sizing:border-box;
      
    -webkit-transform-origin:0 100%;
    -ms-transform-origin:0 100%;
    transform-origin:0 100%;
      
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.diamond {
    width: 0;
    height: 0;
    border: 50px solid transparent;
    border-bottom-color: #fde090;
    position: absolute;
}
.diamond:after {
    content: '';
    position: absolute;
    left: -50px;
    top: 50px;
    width: 0;
    height: 0;
    border: 50px solid transparent;
    border-top-color: #fde090;
  }


.footer-image-esp{
    right: -70px;
}

@media (max-width: 1200px){
    .img_bk_esp{
        width: 800px;
    }
    .header-h1{
        margin-bottom: 10px;
        padding-bottom: 20px;
    }
    .triangle{
        left: 800px;
    }
    .footer-image-esp{
        position: absolute;
        /*right: 50%;
        transform: translate(50%, 0px);*/
        width: 800px;
    }
}
.only-mobile{
    display: none;
}
.white{
    background-color: white !important;
}

@media (min-width: 993px){
    nav ul{
        display: block !important;
    }
}

@media (max-width: 768px){
    .header{
        background-position: center top;
    }
    .img_bk_esp{
        width: calc(100% - 10%);
        height: 300px;   
    }
    .crd-cont-esp{
        /*background: #fff;
        border-radius: 3px;
        box-shadow: 4px 4px 7px 0px rgb(0 0 0 / 0.25);*/
    }
    .no-mobile{
        display: none;
        opacity: 0;
    }
    nav.fixed{
        background: #fff;
        width: 100%;
        border-radius: 0px;
        line-height: 4px;
        box-shadow: 0 14px 26px -12px rgba(33,33,33,.42), 0 4px 23px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(33,33,33,.2);
    }
    nav.scrollable .navbar-logo{
        line-height: 53px;
    }
    .only-mobile{
        display: block;
    }
    .pst-esp li, .pst-esp li a{
        width: 100%;
        text-align: left;
    }
    .desktop-mode{
        margin-top: 64px;
        position: absolute;
        width: 100%;
        height: auto !important;
        z-index: 1;
        left: 0px;
        padding: 0 20px;
    }
    .desktop-mode li{
        padding: 0px !important;
        margin: 0px;
    }
}

.menu-esp{
    color: #000;
    margin: 14px 0;
}
@media (max-width: 554px){
    .header-h1{
        transform: scale(.8);
        margin-top: 5rem;
    }
    .img_bk_esp {
        width: 460px;
        height: 190px;
        margin-bottom: 20px;
    }
    .footer-image-esp{
        width: 300px;
        right: initial;
        transform: scale(.8) translate(16%, 0px);
    }
    .base-line-esp .assistent-esp{
        position: absolute;
        top: 0px;
        left: 100%;
    }
    .triangle{
        display: none
    }
    .myAvatar-esp{
        display: none;
    }
    nav.fixed{
        z-index: 10;
    }
    footer .nvb-wrapper{
        margin: 0 auto !important;
        width: 100% !important;
    }
}

@media (max-width: 450px){
    .footer-image-esp{
        transform: scale(.7) translate(-10%, 0px);
    }
}

@media (max-width: 554px){
    .img_bk_esp {
        width: 384px;
        height: 176px;
        margin-bottom: 20px;
        border: none;
        box-shadow: none;
    }
    .header-h1{
        font-size: 3rem;
        line-height: 3rem;
        width: 410px;
    }
    .material-icons:not(.no-style-font){
        font-size: 35px !important;
    }
    .section_1{
        margin-top: 0px;
        padding-top: 0px !important;
    }
    .hexagon{
        top: 250px !important;
    }
    .sect3 .port_as3 div p i{
        font-size: 1.5rem !important;
    }
    .asd__{
        padding: 0px !important;
    }
    .no-mobile{
        display: none;
        opacity: 0;
    }
    .sect4{
        background-size: cover;
    }
    .sign-cross{
        left: 30px;
        right: auto;

    }
    section .sub-title-esp{
        margin-left: 20px;
    }
    section h2{
        margin-left: 20px;
    }
}

nav .nvb-wrapper ul:not(.no-style) li a{
    font-size: .7rem !important;
    line-height: 50px !important;
}

.l_title_esp{/*
    justify-content: center;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 16px);
    margin: -8px;
    box-sizing: border-box;
    height: 100%;*/
    position: relative;
    top: 0px;
    z-index: 1;
}
section{
    position: relative;
}
.sign-circular{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 6px solid #fae88f;
    position: absolute;
    top: 200px;
    left: 50px;
    z-index: 0;
}
.sign-cross{
    position: absolute;
    right: 100px;
    z-index: 0;
    bottom: 200px;
}
.sign-cross:after{
    content: "";
    width: 25px;
    height: 70px;
    background: #fee392;
    position: absolute;
    border-radius: 30px;
}
.sign-cross:before{
    content: "";
    width: 70px;
    height: 25px;
    background: #fee392;
    position: absolute;
    border-radius: 30px;
    transform: translate(-22px, 22px);
}

.sign-sdf4{
    position: absolute;
    right: 150px;
    z-index: 0;
    top: 100px;
}
.sign-sdf4:after{
    content: "";
    width: 25px;
    height: 70px;
    background: #88d9f6;
    position: absolute;
    border-radius: 30px;
    left: 45px;
}
.sign-sdf4:before{
    content: "";
    width: 70px;
    height: 25px;
    background:#88d9f6;
    position: absolute;
    border-radius: 30px;
}

.loading{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 100000000;
    transition: .3s ease-in-out;
}

.loading.hidden{
    opacity: 0;
    visibility: hidden;
}